import React from 'react';
import { StatusLight, Tooltip } from '@servicetitan/design-system';

export const Usage = ({status}) => {
	let tooltipText;
	switch (status) {
		case "success":
			tooltipText = "Recommended"
			break;
		case "warning":
			tooltipText = "Use with caution"
			break;
		case "critical":
			tooltipText = "DO NOT USE"
			break;
	}
	return <Tooltip text={tooltipText}><StatusLight color={status} /></Tooltip>
}
