/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {DataList} from '@servicetitan/design-system';
import {Usage} from './Usage';
import {ResourceLink, Link} from 'components';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    h3: "h3",
    pre: "pre",
    code: "code",
    strong: "strong",
    hr: "hr"
  }, _provideComponents(), props.components);
  if (!DataList) _missingMdxReference("DataList", false);
  if (!DataList.Cell) _missingMdxReference("DataList.Cell", true);
  if (!DataList.HeaderCell) _missingMdxReference("DataList.HeaderCell", true);
  if (!DataList.Row) _missingMdxReference("DataList.Row", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "overview",
    style: {
      position: "relative"
    }
  }, "Overview", React.createElement(_components.a, {
    href: "#overview",
    "aria-label": "overview permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Too many type sizes and styles at once can spoil any layout. A limited set of type variations are supported that work well with each other."), "\n", React.createElement(_components.h2, {
    id: "usage-guide",
    style: {
      position: "relative"
    }
  }, "Usage Guide", React.createElement(_components.a, {
    href: "#usage-guide",
    "aria-label": "usage guide permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "headline",
    style: {
      position: "relative"
    }
  }, "Headline", React.createElement(_components.a, {
    href: "#headline",
    "aria-label": "headline permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(DataList, {
    className: "DocsTable",
    header: [React.createElement(DataList.HeaderCell, {
      key: 0,
      name: "",
      content: ""
    }), React.createElement(DataList.HeaderCell, {
      key: 1,
      name: "xlarge",
      content: "xlarge"
    }), React.createElement(DataList.HeaderCell, {
      key: 2,
      name: "large",
      content: "large"
    }), React.createElement(DataList.HeaderCell, {
      key: 3,
      name: "medium",
      content: "medium"
    }), React.createElement(DataList.HeaderCell, {
      key: 4,
      name: "small",
      content: "small"
    })]
  }, React.createElement(DataList.Row, null, React.createElement(DataList.Cell, null, "On-boarding Wizard"), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  }))), React.createElement(DataList.Row, null, React.createElement(DataList.Cell, null, React.createElement(Link, {
    href: "/components/page/"
  }, "Page Title")), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  }))), React.createElement(DataList.Row, null, React.createElement(DataList.Cell, null, "Section Title"), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  }))), React.createElement(DataList.Row, null, React.createElement(DataList.Cell, null, React.createElement(Link, {
    href: "/components/card/#examples"
  }, "Card Title")), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  }))), React.createElement(DataList.Row, null, React.createElement(DataList.Cell, null, React.createElement(Link, {
    href: "/components/modal/#default-modal"
  }, "Modal Title")), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  }))), React.createElement(DataList.Row, null, React.createElement(DataList.Cell, null, React.createElement(Link, {
    href: "/components/drawer/#header-examples"
  }, "Drawer Title")), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  }))), React.createElement(DataList.Row, null, React.createElement(DataList.Cell, null, React.createElement(Link, {
    href: "/components/popover/#medium"
  }, "Popover Title")), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  })))), "\n", React.createElement(_components.h3, {
    id: "body-text",
    style: {
      position: "relative"
    }
  }, "Body Text", React.createElement(_components.a, {
    href: "#body-text",
    "aria-label": "body text permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(DataList, {
    className: "DocsTable",
    header: [React.createElement(DataList.HeaderCell, {
      key: 0,
      name: "",
      content: ""
    }), React.createElement(DataList.HeaderCell, {
      key: 1,
      name: "xlarge",
      content: "xlarge"
    }), React.createElement(DataList.HeaderCell, {
      key: 2,
      name: "large",
      content: "large"
    }), React.createElement(DataList.HeaderCell, {
      key: 3,
      name: "medium",
      content: "medium"
    }), React.createElement(DataList.HeaderCell, {
      key: 4,
      name: "small",
      content: "small"
    })]
  }, React.createElement(DataList.Row, null, React.createElement(DataList.Cell, null, "Paragraph"), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  }))), React.createElement(DataList.Row, null, React.createElement(DataList.Cell, null, "Table Cell"), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  }))), React.createElement(DataList.Row, null, React.createElement(DataList.Cell, null, "Single Piece of Data"), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  }))), React.createElement(DataList.Row, null, React.createElement(DataList.Cell, null, "Helper Text"), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  }))), React.createElement(DataList.Row, null, React.createElement(DataList.Cell, null, "Meta Data"), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "critical"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "warning"
  })), React.createElement(DataList.Cell, {
    width: "70px"
  }, React.createElement(Usage, {
    status: "success"
  })))), "\n", React.createElement(_components.h2, {
    id: "pairing",
    style: {
      position: "relative"
    }
  }, "Pairing", React.createElement(_components.a, {
    href: "#pairing",
    "aria-label": "pairing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Our Typography uses semantic naming to pair sizes which helps with the consistency in overall application as well as the visual hierarchy and space management."), "\n", React.createElement(_components.p, null, "This pairing method is ideal for text components that are directly or semi-directly related or grouped together in a card or section."), "\n", React.createElement(_components.h3, {
    id: "pairing-examples",
    style: {
      position: "relative"
    }
  }, "Pairing Examples", React.createElement(_components.a, {
    href: "#pairing-examples",
    "aria-label": "pairing examples permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\n---\n<Card>\n\t<Headline>Headline Default</Headline>\n\t<br />\n\t<Eyebrow>Eyebrow Default</Eyebrow>\n\t<BodyText>This is default BodyText component</BodyText>\n</Card>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\n---\n<Card>\n\t<Headline size='large' className='m-b-3'>Headline Large</Headline>\n\n\t<Eyebrow size='large'>Eyebrow Large</Eyebrow>\n\t<BodyText size='large'>This is Large BodyText component</BodyText>\n</Card>\n")), "\n", React.createElement(_components.p, null, "Use same size for sibling items."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\n---\n<Card>\n\t<Card.Section>\n\t\t<Headline size='small'>Headline Small</Headline>\n\t\t<BodyText size='small'>This is Small BodyText component</BodyText>\n\t</Card.Section>\n\t<Card.Section>\n\t\t<Headline size='small' className='m-b-2'>Headline Small</Headline>\n\t\t<Eyebrow size='small'>Eyebrow Small</Eyebrow>\n\t\t<BodyText size='small' className='m-b-3'>This is Small BodyText component</BodyText>\n\t\t<Form>\n\t\t\t<Form.Input label=\"First Name\" placeholder=\"Leia\" />\n\t\t</Form>\n\t</Card.Section>\n</Card>\n")), "\n", React.createElement(_components.h2, {
    id: "deviation",
    style: {
      position: "relative"
    }
  }, "Deviation", React.createElement(_components.a, {
    href: "#deviation",
    "aria-label": "deviation permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "In some cases where you need higher contrast between Headline and Body, Headline can size up one ", React.createElement(_components.strong, null, "or"), " body can size down one. Do not deviate up with body text, the body text and headline size would be the same creating ineffective typographical hierarchy."), "\n", React.createElement(_components.h3, {
    id: "deviation-examples",
    style: {
      position: "relative"
    }
  }, "Deviation Examples", React.createElement(_components.a, {
    href: "#deviation-examples",
    "aria-label": "deviation examples permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\n---\n<Card>\n<Card.Section>\n\t<Eyebrow size='medium'>Normal</Eyebrow>\n\t<Headline size='large'>Headline Large</Headline>\n\t<BodyText size='large' className='m-b-4'>BodyText Large</BodyText>\n\t<Eyebrow size='medium'>Deviation</Eyebrow>\n\t<Headline size='xlarge'>Headline xLarge</Headline>\n\t<BodyText size='large'>BodyText Large</BodyText>\n</Card.Section>\n<Card.Section>\n\t<Eyebrow size='medium'>Normal</Eyebrow>\n\t<Headline size='medium'>Headline Medium</Headline>\n\t<BodyText size='medium' className='m-b-4'>BodyText Medium</BodyText>\n\t<Eyebrow size='medium'>Deviation</Eyebrow>\n\t<Headline size='medium' >Headline Medium</Headline>\n\t<BodyText size='small'>BodyText Small</BodyText>\n</Card.Section>\n<Card.Section>\n\t<Eyebrow size='medium'>Normal</Eyebrow>\n\t<Headline size='small'>Headline Small</Headline>\n\t<BodyText size='small' className='m-b-4'>BodyText Small</BodyText>\n\t<Eyebrow size='medium'>Deviation</Eyebrow>\n\t<Headline size='small'>Headline Small</Headline>\n\t<BodyText size='xsmall'>BodyText xSmall</BodyText>\n</Card.Section>\n</Card>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "variations",
    style: {
      position: "relative"
    }
  }, "Variations", React.createElement(_components.a, {
    href: "#variations",
    "aria-label": "variations permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "headline-1",
    style: {
      position: "relative"
    }
  }, "Headline", React.createElement(_components.a, {
    href: "#headline-1",
    "aria-label": "headline 1 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "Font Family: Sofia Pro"), React.createElement("br"), "\n", React.createElement(_components.code, null, "Weight: Bold")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<>\n\t<Headline size='xlarge' className='m-b-2'>X-large, 32px</Headline>\n\t<Headline size='large' className='m-b-2'>Large, 26px</Headline>\n\t<Headline size='medium' className='m-b-2'>Medium, 20px</Headline>\n\t<Headline size='small' className='m-b-2'>Small, 16px</Headline>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "body-text-1",
    style: {
      position: "relative"
    }
  }, "Body Text", React.createElement(_components.a, {
    href: "#body-text-1",
    "aria-label": "body text 1 permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "Font Family: Nunito Sans"), React.createElement("br"), "\n", React.createElement(_components.code, null, "Weight: Regular, Bold")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<>\n\t<BodyText className='m-b-2' size='large'>Large, 20px</BodyText>\n\t<BodyText className='m-b-2' size='medium'>Medium, 16px</BodyText>\n\t<BodyText className='m-b-2' size='small'>Small, 14px</BodyText>\n\t<BodyText className='m-b-2' size='xsmall'>X-small, 12px</BodyText>\n</>\n")), "\n", React.createElement(_components.h2, {
    id: "eyebrow",
    style: {
      position: "relative"
    }
  }, "Eyebrow", React.createElement(_components.a, {
    href: "#eyebrow",
    "aria-label": "eyebrow permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.code, null, "Font Family: Nunito Sans"), React.createElement("br"), "\n", React.createElement(_components.code, null, "Weight: Semibold"), React.createElement("br"), "\n", React.createElement(_components.code, null, "Case: Uppercase"), React.createElement("br"), "\n", React.createElement(_components.code, null, "Style: Subdued")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<>\n\t<Eyebrow className='m-b-2' size='large'>Large, 14px</Eyebrow>\n\t<Eyebrow className='m-b-2' size='medium'>Medium, 12px</Eyebrow>\n\t<Eyebrow className='m-b-2' size='small'>Small, 10px</Eyebrow>\n</>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "styles",
    style: {
      position: "relative"
    }
  }, "Styles", React.createElement(_components.a, {
    href: "#styles",
    "aria-label": "styles permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h2, {
    id: "subdued",
    style: {
      position: "relative"
    }
  }, "Subdued", React.createElement(_components.a, {
    href: "#subdued",
    "aria-label": "subdued permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "You can deemphasize text by making it a lighter shade of grey."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\n---\n<Card>\n<BodyText>In a paragraph of text, when you have words that need to be deemphasized, <BodyText inline el='span' subdued>subdued text</BodyText> becomes less apparent</BodyText>\n</Card>\n")), "\n", React.createElement(_components.p, null, "A subdued line of copy can be used for disclaimers or helpful notes below form fields"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\n---\n<Card>\n\t<BodyText subdued>You can only upload photos in jpg, png, and tif formats</BodyText>\n</Card>\n")), "\n", React.createElement(_components.p, null, "Subdued headlines provide extra context to a regular headline"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\n---\n<Card>\n\t<Headline subdued>Headline</Headline>\n</Card>\n")), "\n", React.createElement(_components.h2, {
    id: "italic",
    style: {
      position: "relative"
    }
  }, "Italic", React.createElement(_components.a, {
    href: "#italic",
    "aria-label": "italic permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "You can emphasize text by italicizing it."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<BodyText>In a paragraph of text, when you have words that need to be deemphasized, <BodyText inline el='span' italic>subdued text</BodyText> becomes less apparent</BodyText>\n")), "\n", React.createElement(_components.h2, {
    id: "bold",
    style: {
      position: "relative"
    }
  }, "Bold", React.createElement(_components.a, {
    href: "#bold",
    "aria-label": "bold permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "You can emphasize text by bolding it."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "inline: true\n---\n<Card>\n\t<BodyText>In a paragraph of text, when you have words that need to be emphasized, <BodyText inline el='span' bold>bold text</BodyText> becomes more apparent</BodyText>\n</Card>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "additional-information",
    style: {
      position: "relative"
    }
  }, "Additional Information", React.createElement(_components.a, {
    href: "#additional-information",
    "aria-label": "additional information permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Refer to ", React.createElement(_components.a, {
    href: "/components/typography/"
  }, "Typography"), " component for more information about how it is implemented."), "\n", React.createElement(_components.h2, {
    id: "get-fonts",
    style: {
      position: "relative"
    }
  }, "Get Fonts", React.createElement(_components.a, {
    href: "#get-fonts",
    "aria-label": "get fonts permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(ResourceLink, {
    title: "Nunito Sans (Google Fonts)",
    subtitle: "2 Weights, 2 Styles",
    url: "https://fonts.google.com/specimen/Nunito+Sans?selection.family=Nunito+Sans:400,600",
    download: true
  }));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
